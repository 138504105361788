<template>
  <!-- <div class="my-pushu"> -->
  <div class="my-pushu">
    <div class="content-inner">
      <div class="pushu-header">
        <div class="title-text">
          已观看收费谱书 (<span>{{ resultList.length }}</span
          >/{{ userVip.viewcount || 0 }})
        </div>
      </div>
      <div v-loading="loading" class="result-list">
        <div
          v-for="item in resultList"
          class="result-item"
          :key="item.id"
          @click="navigateToDetail(item.id)"
        >
          <div v-if="item.price == 0" class="tag-container">
            免费
          </div>
          <div v-else class="tag-container charge">
            收费
          </div>
          <div class="item-cover">
            <img class="item-cover" :src="item.cover_image" alt="item-cover" />
            <div v-if="item.status === 1" class="status status-1">已上架</div>
            <div v-else-if="item.status === 2" class="status status-2">
              下架
            </div>
            <div v-else class="status status-0">未审核</div>
          </div>
          <div class="item-content">
            <div class="item-content-inner">
              <div class="item-info">
                <span class="label">
                  题名：
                </span>
                <span class="value">
                  {{ item.title }}
                </span>
              </div>
              <div class="item-info">
                <span class="label">
                  堂号：
                </span>
                <span class="value">
                  {{ item.tanghao || "无" }}
                </span>
              </div>
              <div class="item-info">
                <span class="label">
                  卷数：
                </span>
                <span class="value">
                  {{ item.juanshu || "无" }}
                </span>
              </div>
              <div class="item-info">
                <span class="label">
                  谱籍地：
                </span>
                <span class="value">
                  {{ item.pujidi || "无" }}
                </span>
              </div>
              <div class="item-info">
                <span class="label">
                  编撰日期：
                </span>
                <span class="value">
                  {{ item.zhuanxieriqi || "无" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next"
        :page-count="maxPage"
        @prev-click="fetchList"
        @next-click="fetchList"
        @current-change="fetchList"
      >
      </el-pagination>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapState } from "vuex";
import { Pagination } from "element-ui";
import materialApis from "@/apis/material";

// const mockData = new Array(30).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   timing: "题目",
//   tanghao: "堂号",
//   fayuandi: "发源地",
//   zhuanxieriqi: "编撰日期",
//   price: [0, 1][index % 2],
//   cover_image: require("@/assets/images/search/cover.png")
// }));

export default {
  name: "Watched",
  components: {
    "el-pagination": Pagination
    // "el-button": Button
  },
  data() {
    return {
      page: 1,
      loading: false,
      maxPage: 0,
      resultList: []
    };
  },
  computed: {
    ...mapState(["vipIndex", "userInfo"]),
    userVip() {
      return (
        this.vipIndex.find(item => item.id === this.userInfo.vip_level) || {}
      );
    }
  },
  created() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const {
          data: { resource_favorite_list, max_page }
        } = await materialApis.watchedmMterial({ page: this.page });
        this.maxPage = max_page;
        this.resultList = resource_favorite_list.map(
          item => item.material_info
        );
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },

    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    },

    navigateToDetail(id) {
      this.$router.push({
        path: "/web/search/detail",
        query: { id }
      });
    },

    navigateToUpload() {
      this.$router.push({
        path: "/web/user/upload"
      });
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.my-pushu {
  padding: 20px;
  height: calc(100% - 50px);
  box-sizing: border-box;

  .content-inner {
    height: calc(100% - 40px);
    width: 100%;
    background: #f7f7f7;
    border-radius: 4px;
    // padding: 0 20px;
    box-sizing: border-box;
  }

  .pushu-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .title-text {
      font-size: 14px;
      color: #666666;

      span {
        color: #CA0A15;
        font-weight: bold;
      }
    }

    .upload-btn {
      width: 90px;
      height: 30px;
      background: #CA0A15;
      border-radius: 4px;
      color: #ffffff;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
    }
  }

  .result-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    overflow: auto;
    height: calc(100% - 50px);
    width: calc(100% + 25px);
    padding: 20px;
    box-sizing: border-box;

    .result-item {
      border-radius: 4px;
      overflow: hidden;
      align-items: center;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      height: 344px;
      position: relative;
      width: 200px;
      margin-right: 26px;
      margin-bottom: 30px;
      cursor: pointer;

      .tag-container {
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        height: 30px;
        width: 60px;
        left: 10px;
        overflow: hidden;
        position: absolute;
        top: 10px;
        color: #2fc507;
        font-size: 12px;

        &.charge {
          color: #fdae04;
        }
      }
    }

    .item-cover {
      height: 200px;
      width: 200px;
      object-fit: cover;
      position: relative;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);

      .status {
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        position: absolute;
        bottom: 0;
        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.5);

        &.status-0 {
          color: #ff5500;
        }

        &.status-1 {
          color: #1aa97b;
        }

        &.status-2 {
          color: #f02b2b;
        }
      }
    }

    .item-content {
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      flex-direction: row;
      height: 140px;
      overflow: hidden;
      position: relative;
      width: 200px;

      .item-content-inner {
        align-items: flex-start;
        justify-content: space-between;
        border-color: #CA0A15;
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 120px;
        overflow: hidden;
        width: 180px;
        padding: 14px 10px;

        .item-info {
          font-size: 10px;

          .label {
            color: #CA0A15;
            line-height: 10px;
            text-decoration: none;
          }

          .value {
            color: #8a7571;
            text-decoration: none;
          }
        }
      }
    }

    .wrap {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 90px;
      margin-left: 9px;
      margin-top: 12px;
      position: relative;
      width: 140px;
    }
  }
}

.pager {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>
